const CAKES = 'cakes';
const DRINKS = 'drinks';
const QUICHES = 'quiches';
const DESSERTS = 'desserts';
const ICECREAM = 'icecream';
const GALLERY = 'gallery';
const ABOUT = 'about';

export const navigationLeft = [CAKES, QUICHES, DESSERTS, DRINKS];
export const navigationRight = [ICECREAM, GALLERY, ABOUT];

export const availableProducts = [CAKES, QUICHES, DESSERTS, DRINKS, ICECREAM];
