import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';

import getTheme from './theme';

export const CustomThemeContext = React.createContext({
  currentTheme: 'normal',
  setTheme: null,
});

const CustomThemeProvider = ({ children }) => {
  const currentTheme = localStorage.getItem('appTheme') || 'default';

  const [themeName, setThemeName] = useState(currentTheme);

  const theme = getTheme(themeName);

  const setTheme = (name) => {
    localStorage.setItem('appTheme', name);
    setThemeName(name);
  };

  const contextValue = {
    currentTheme: themeName,
    setTheme,
  };

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider;
