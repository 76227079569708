import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SwipeableDrawer,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

import GalleryFilters from '../GalleryFilters';
import CloseIcon from '@mui/icons-material/Close';

const GalleryFiltersDialog = ({ items, open, onChange, onOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <SwipeableDrawer open={open} onOpen={onOpen} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        {t('common.filters')}

        <IconButton onClick={onClose} sx={{ marginLeft: 'auto' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <GalleryFilters items={items} onChange={onChange} />
      </DialogContent>
    </SwipeableDrawer>
  );
};

export default GalleryFiltersDialog;
