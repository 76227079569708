import React from 'react';
import { Box, AppBar, Toolbar, Skeleton, Container, Grid } from '@mui/material';

import Logo from '../Logo';

const Loader = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky"
        color="inherit"
        sx={{ boxShadow: '0 1px 25px rgba(0, 0, 0, 0.15)' }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
          <Logo variant="circle" sx={{ marginBottom: -6 }} />
        </Toolbar>
      </AppBar>

      <Skeleton height={227} sx={{ marginTop: -6.1 }} />

      <Container>
        <Skeleton sx={{ width: '70%' }} />

        <Skeleton sx={{ width: '50%' }} />

        <Skeleton sx={{ width: '30%', marginBottom: 2 }} />

        <Grid container spacing={3}>
          {new Array(4).fill({}).map((_, i) => (
            <Grid item xs={12} sm={3} key={i}>
              <Box p={2} sx={{ borderRadius: 2, boxShadow: 2 }}>
                <Skeleton height={300} />
                <Skeleton />
                <Skeleton />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Loader;
