import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Hidden } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

const HeaderOrderButton = ({ onClick = () => {}, size, sx }) => {
  const { t } = useTranslation();

  return (
    <>
      <Hidden mdUp>
        <IconButton onClick={onClick} color="primary" sx={sx}>
          <PhoneIcon />
        </IconButton>
      </Hidden>

      <Hidden mdDown>
        <Button onClick={onClick} variant="outlined" sx={sx}>
          <PhoneIcon sx={{ marginRight: 1 }} />
          {t('common.order')}
        </Button>
      </Hidden>
    </>
  );
};

export default HeaderOrderButton;
