import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const FooterOpenHours = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h6" gutterBottom className="font-roboto-slab">
        {t('common.openHours')}
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        {t('common.weekdays')}: 9:00 - 19:00
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        {t('common.weekend')}: 10:00 - 18:00
      </Typography>
    </>
  );
};

export default FooterOpenHours;
