import React, { memo } from 'react';
import { AppBar, Toolbar, Container, Grid } from '@mui/material';

import FooterOpenHours from './components/FooterOpenHours';
import FooterCopyright from './components/FooterCopyright';
import FooterLocation from './components/FooterLocation';
import FooterSocials from './components/FooterSocials';

const Footer = () => {
  return (
    <AppBar position="static" color="default" component="footer">
      <Toolbar component={Container} sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} sm={4} md="auto">
            <FooterLocation />
          </Grid>

          <Grid item xs={12} sm={4} md="auto">
            <FooterOpenHours />
          </Grid>

          <Grid item xs={12} sm={4} md="auto">
            <FooterSocials />
          </Grid>
        </Grid>
      </Toolbar>

      <FooterCopyright />
    </AppBar>
  );
};

export default memo(Footer);
