import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

const FormCheckbox = ({ name, label, checked, onChange }) => {
  return (
    <FormControlLabel
      control={<Checkbox name={name} checked={checked} onChange={onChange} />}
      label={label}
    />
  );
};

export default FormCheckbox;
