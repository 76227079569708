import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

import Header from 'components/CommonComponents/Header';
import Footer from 'components/CommonComponents/Footer';
import ScrollTop from 'components/CommonComponents/ScrollTop';

const MainLayout = ({ children }) => {
  const location = useLocation();

  useEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), [location]);

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
      <Header />

      <Box
        component="main"
        sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
      >
        {children}

        <ScrollTop />
      </Box>

      <Footer />
    </Box>
  );
};

export default MainLayout;
