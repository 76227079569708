import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Button, Typography, Hidden } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const GalleryFiltersHeader = ({ isFiltersSelected, onClick, onClear }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" mb={2} component={Box}>
      <Grid item xs>
        <Hidden mdDown>
          <Typography variant="h5" sx={{ lineHeight: '40px' }}>
            {t('common.filters')}
          </Typography>
        </Hidden>

        <Hidden mdUp>
          <Button onClick={onClick} variant="outlined">
            {t('common.filters')}
          </Button>
        </Hidden>
      </Grid>

      {isFiltersSelected && (
        <Grid item>
          <Button onClick={onClear} endIcon={<CloseIcon />}>
            {t('common.clearFilters')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default GalleryFiltersHeader;
