import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Box, Button } from '@mui/material';

const HeaderNavigation = ({ orientation = 'horizontal', items = [], sx }) => {
  const { url: routeUrl } = useRouteMatch();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: orientation === 'horizontal' ? 'row' : 'column',
        minWidth: 240,
        ...sx,
      }}
    >
      {items.map(({ id, name, url }) => (
        <Button
          to={url}
          key={id}
          color="primary"
          component={Link}
          disabled={routeUrl.includes(url)}
          sx={{
            borderRadius: 0,
            whiteSpace: 'nowrap',
            '&.Mui-disabled': { color: 'secondary.dark' },
          }}
        >
          {name}
        </Button>
      ))}
    </Box>
  );
};

export default HeaderNavigation;
