import { selector, selectorFamily } from 'recoil';

import firebase from '../firebase';

const database = firebase.firestore();

export const getCollection = async (collection) => {
  if (!collection) return [];

  const querySnapshot = await database
    .collection(collection)
    .where('status', '==', 'published')
    .get();
  const data = [];

  querySnapshot.forEach((query) => data.push(query.data()));

  return data;
};

export const getCollectionData = async (collection, id) => {
  const snapshot = await database
    .collection(collection)
    .where('id', '==', id)
    .get();

  const docs = [];

  snapshot.forEach((query) => docs.push(query.data()));

  return docs;
};

export const scenesState = selector({
  key: 'scenesKey',
  get: async () => getCollection('scenes'),
});

export const sceneState = selectorFamily({
  key: 'sceneKey',
  get: (id) => async () => getCollectionData('scenes', id),
});

export const bannersState = selector({
  key: 'bannersKey',
  get: async () => getCollection('banners'),
});

export const proposalsState = selector({
  key: 'proposalsKey',
  get: async () => getCollection('proposals'),
});

export const mainDishesState = selector({
  key: 'mainDishesKey',
  get: async () => getCollection('main_dishes'),
});

export const galleryState = selector({
  key: 'galleryKey',
  get: async () => getCollection('gallery'),
});

export const productsCardsState = selectorFamily({
  key: 'productsCards',
  get: (productsId) => async () => getCollection(productsId),
});

export const productCardState = selectorFamily({
  key: 'productCardKey',
  get:
    ({ productsId, id }) =>
    async () =>
      getCollectionData(productsId, id),
});

const api = (key) => {
  selectorFamily({ key, get: async () => getCollection(key) });
};

export default api;
