import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { initReactI18next } from 'react-i18next';
import { CssBaseline } from '@mui/material';
import { RecoilRoot } from 'recoil';
import i18next from 'i18next';

import CustomThemeProvider from './themeProvider';

import Loader from 'components/CommonComponents/Loader';

import resources from 'langs';

import Routes from 'routes';

i18next.use(initReactI18next).init({ lng: 'uk', fallbackLng: 'uk', resources });

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <CustomThemeProvider>
        <HelmetProvider>
          <CssBaseline />
          <RecoilRoot>
            <Routes />
          </RecoilRoot>
        </HelmetProvider>
      </CustomThemeProvider>
    </Suspense>
  );
};

export default App;
