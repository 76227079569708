import React from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = ({
  component: Component,
  layout: Layout,
  computedMatch,
  location,
  history,
  exact,
  path,
}) => (
  <Route
    computedMatch={computedMatch}
    location={location}
    history={history}
    exact={exact}
    path={path}
    render={({
      match: routeMatch,
      history: routeHistory,
      staticContext: routeStaticContext,
    }) => {
      if (!Layout) {
        return (
          <Component
            history={routeHistory}
            location={location}
            match={routeMatch}
            staticContext={routeStaticContext}
          />
        );
      }

      return (
        <Layout
          history={routeHistory}
          location={location}
          match={routeMatch}
          staticContext={routeStaticContext}
        >
          <Component
            history={routeHistory}
            location={location}
            match={routeMatch}
            staticContext={routeStaticContext}
          />
        </Layout>
      );
    }}
  />
);

export default PublicRoute;
