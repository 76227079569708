import React from 'react';
import { DialogTitle, Typography, IconButton, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ImageDialogTitle = ({ name, onClose }) => {
  return (
    <DialogTitle>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs>
          <Typography variant="h5">{name}</Typography>
        </Grid>

        <Grid item xs="auto">
          <IconButton edge="end" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default ImageDialogTitle;
