import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Card,
  CardMedia,
  Container,
  Typography,
  Button,
} from '@mui/material';

const ProductBanner = ({ name, image, content, background }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Card
      elevation={0}
      image={background}
      component={CardMedia}
      sx={{ borderRadius: 0 }}
    >
      <Container disableGutters>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={5}>
            <CardMedia component="img" image={image} height="100%" />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={7}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CardMedia
              component="img"
              image="/cakes.svg"
              sx={{ maxWidth: 100 }}
            />

            <Typography
              variant="h2"
              align="center"
              className="font-roboto-slab"
              gutterBottom
              sx={{ [theme.breakpoints.down('md')]: { fontSize: '2.125rem' } }}
            >
              {name}
            </Typography>

            <Typography
              variant="h4"
              align="center"
              className="font-roboto-slab"
              gutterBottom
              sx={{ [theme.breakpoints.down('md')]: { fontSize: '1.25rem' } }}
            >
              {content}
            </Typography>

            <Button
              to="/about"
              color="primary"
              variant="outlined"
              component={Link}
              sx={{ marginTop: 3 }}
            >
              {t('common.details')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
};

export default ProductBanner;
