import React, { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { bannersState, proposalsState, mainDishesState } from '../../api';

import ProductBanner from 'components/ProductsComponents/ProductBanner';
import ProductProposalCard from 'components/ProductsComponents/ProductProposalCard';

import WeServe from './components/WeServe';

const HomeScene = () => {
  const { t } = useTranslation();
  const [banner] = useRecoilValue(bannersState) || [];
  const [proposal] = useRecoilValue(proposalsState) || [];
  const mainDishes = useRecoilValue(mainDishesState) || [];

  return (
    <>
      <Helmet>
        <title>{t('common.brand').concat(' | ', t('meta.common.title'))}</title>

        <meta
          name="description"
          content={t('common.brand').concat(
            ' | ',
            t('meta.common.description')
          )}
        />
      </Helmet>

      {banner && <ProductBanner {...banner} />}

      {mainDishes && <WeServe items={mainDishes} />}

      {proposal && <ProductProposalCard {...proposal} />}
    </>
  );
};

export default memo(HomeScene);
