import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import {
  Box,
  Grid,
  CardMedia,
  Container,
  Divider,
  Typography,
} from '@mui/material';

import { sceneState, productCardState } from 'api';

import ProductTitle from 'components/ProductsComponents/ProductTitle';
import ImageDialog from 'components/CommonComponents/ImageDialog';

const ProductScene = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { productsId, id } = useParams();
  const [product, setProduct] = useState();
  const availableParams = ['cakes', 'quiches', 'desserts', 'drinks'];

  if (!availableParams.includes(productsId)) history.push('/404');

  const [{ payment } = {}] = useRecoilValue(sceneState(productsId)) || [];
  const [{ name, image, content, price } = {}] = useRecoilValue(
    productCardState({ productsId, id })
  );

  const getContent = (content) => ({ __html: content });

  return (
    <>
      <ProductTitle name={name} />

      <Container pt={2} pb={5} component={Box}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <CardMedia
              image={image}
              component="img"
              onClick={() => setProduct({ image })}
              sx={{ cursor: 'pointer', borderRadius: 3 }}
            />
          </Grid>

          <Grid item xs={12} sm={7}>
            <Box mb={2} dangerouslySetInnerHTML={getContent(content)} />

            <Typography variant="h5" color="primary.dark" gutterBottom>
              {price}
            </Typography>

            {payment && (
              <>
                <Box my={2} component={Divider} />

                <Typography variant="h5" gutterBottom>
                  {t('common.paymentAndDelivery')}
                </Typography>

                <Box mb={1} dangerouslySetInnerHTML={getContent(payment)} />
              </>
            )}
          </Grid>
        </Grid>
      </Container>

      <ImageDialog
        {...product}
        open={!!product}
        onClose={() => setProduct(undefined)}
      />
    </>
  );
};

export default ProductScene;
