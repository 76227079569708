import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Typography } from '@mui/material';

const FooterLocation = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h6" gutterBottom className="font-roboto-slab">
        {t('common.location')}
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        {t('common.address')}
      </Typography>

      <Link
        variant="subtitle2"
        href={t('common.addressUrl')}
        target="_blank"
        gutterBottom
      >
        {t('common.seeAddress')}
      </Link>
    </>
  );
};

export default FooterLocation;
