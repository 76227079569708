import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import Socials from '../../../Socials';

const FooterSocials = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h6" gutterBottom className="font-roboto-slab">
        {t('common.followUs')}
      </Typography>

      <Socials colored spacing={1} />
    </>
  );
};

export default FooterSocials;
