import { createTheme } from '@mui/material/styles';
import { lightBlue } from '@mui/material/colors';

const lightPalette = {
  primary: {
    dark: '#7a5da3', // ФІОЛЕТОВИЙ
    main: '#877eb8', // ФІОЛЕТОВА ПАСТЕЛЬ
    light: '#b8adea',
    contrastText: '#ffffff',
  },
  secondary: {
    dark: '#5b9d8c',
    main: '#8bcebc', // М’ЯТНА ПАСТЕЛЬ
    light: '#bdffee',
    contrastText: '#ffffff',
  },
  custom: {
    dark: '#333333',
    main: '#ffffff',
    light: '#fff8ef', // БЕЖЕВА ПАСТЕЛЬ
    akcent: '#f4a93f', // ОРАНЖЕВИЙ
    lightAkcent: '#dcacce', // РОЖЕВА ПАСТЕЛЬ
  },
  text: {
    primary: '#333333',
    secondary: '#808080',
    link: lightBlue[500],
  },
};

const darkPalette = {
  // primary: {},
  // secondary: {},
};

const components = {
  MuiCssBaseline: {
    styleOverrides: `
      body {
        font-family: 'Nunito', sans-serif;
      }
      .font-roboto-slab: {
        font-family: 'Roboto Slab', sans-serif;
      }
      ul,
      ol,
      p:not([class*="Typography"]) {
        margin-top: 0;
        margin-bottom: 0.35em;
      }
      ul {
        padding-left: 25px;
      }
      a {
        color: ${lightBlue[500]};
      }
    `,
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        fontFamily: '"Nunito", sans-serif',
        fontWeight: 400,
        '&.font-roboto-slab': {
          fontFamily: '"Roboto Slab", sans-serif',
        },
        '&.font-intro-bold': {
          fontFamily: '"Intro-Black", sans-serif',
        },
        '&.text-shadow': {
          textShadow: '0 2px 10px rgba(0, 0, 0, 0.25)',
        },
      },
    },
  },
  MuiBox: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 10,
        transition: 'all 0.25s ease-out',
        '&[href]': {
          textDecoration: 'none',
        },
        '& .MuiTouchRipple-child': {
          backgroundColor: 'custom.light',
          opacity: 0.2,
        },
        '&:not(.MuiCardMedia-root):hover': {
          boxShadow: '0 1px 25px rgba(0, 0, 0, 0.25)',
          '& img': {
            transform: 'scale(1.1)',
          },
        },
      },
      outlined: {
        borderColor: 'custom.light',
      },
    },
  },
  MuiCardActionArea: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'inherit',
        justifyContent: 'flex-start',
        '&[span]': {
          display: 'flex',
        },
      },
      focusHighlight: {
        opacity: 0.01,
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      },
    },
  },
  MuiCardMedia: {
    styleOverrides: {
      root: {
        fontSize: 0,
        transition: 'all 0.25s ease-out',
        img: {
          textIndent: '-100%',
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation1: {
        boxShadow: '0 1px 5px rgba(0, 0, 0, 0.09)',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 10,
      },
      sizeMedium: {
        height: 40,
      },
    },
  },
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        textAlign: 'center',
      },
    },
  },
};

export const lightTheme = createTheme({
  components,
  palette: {
    mode: 'light',
    ...lightPalette,
  },
});

export const darkTheme = createTheme({
  components,
  palette: {
    mode: 'dark',
    ...darkPalette,
  },
});

export default function getTheme(theme) {
  return {
    default: lightTheme,
    dark: darkTheme,
  }[theme || 'default'];
}
