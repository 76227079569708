import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Container, Hidden } from '@mui/material';

import { galleryState } from 'api';

import ProductTitle from 'components/ProductsComponents/ProductTitle';
import ProductCard from 'components/ProductsComponents/ProductCard';
import ImageDialog from 'components/CommonComponents/ImageDialog';
import GalleryHeader from './components/GalleryFiltersHeader';
import GalleryDialog from './components/GalleryFiltersDialog';
import GalleryFilters from './components/GalleryFilters';

const GalleryScene = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState();
  const items = useRecoilValue(galleryState);

  const [filters, setFilters] = useState(
    [...new Set(items.map(({ tags = '' }) => tags.split(', ')).flat())].map(
      (label) => ({ label, checked: false })
    )
  );

  const isFiltersSelected = filters.some(({ checked }) => checked);

  const gallery = items.filter(
    ({ tags }) =>
      !isFiltersSelected ||
      filters.some(({ label, checked }) => tags.includes(label) && checked)
  );

  const handleProduct = (id) => {
    setProduct(items.find(({ image }) => image === id));
  };

  const handleFilter = ({ target: { name } }, checked) => {
    setFilters(
      filters.map(({ label, checked: filterChecked }) => ({
        label,
        checked: label === name ? checked : filterChecked,
      }))
    );
  };

  const handleClear = () => {
    setFilters(filters.map((item) => ({ ...item, checked: false })));
  };

  return (
    <>
      <Helmet>
        <title>{t('common.brand').concat(' | ', t('meta.common.title'))}</title>

        <meta
          name="description"
          content={t('common.brand').concat(
            ' | ',
            t('meta.common.description')
          )}
        />
      </Helmet>

      <ProductTitle name={t('common.gallery')} />

      <Box py={2} component={Container}>
        <GalleryHeader
          isFiltersSelected={isFiltersSelected}
          onClick={() => setOpen(true)}
          onClear={handleClear}
        />

        <Grid container spacing={2}>
          <Hidden mdDown>
            <Grid item xs={12} md={3}>
              <GalleryFilters items={filters} onChange={handleFilter} />
            </Grid>
          </Hidden>

          <Grid item xs={12} md={9}>
            <Grid container spacing={1}>
              {gallery.map(({ image }, i) => (
                <Grid item xs={12} sm={6} md={4} key={i}>
                  <ProductCard
                    id={image}
                    image={image}
                    onImageClick={handleProduct}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Hidden mdUp>
        <GalleryDialog
          open={open}
          items={filters}
          onChange={handleFilter}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
        />
      </Hidden>

      <ImageDialog {...product} open={!!product} onClose={() => setProduct()} />
    </>
  );
};

export default GalleryScene;
