import React, { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Container, Grid, Hidden } from '@mui/material';

import { scenesState } from '../../../api';
import { navigationLeft, navigationRight } from '../../../enums';

import HeaderScrollEffect from './components/HeaderScrollEffect';
import HeaderOrderButton from './components/HeaderOrderButton';
import HeaderNavigation from './components/HeaderNavigation';
import HeaderMenuDrawer from './components/HeaderMenuDrawer';
import Socials from '../Socials';
import Logo from '../Logo';

const Header = () => {
  const { t } = useTranslation();
  const items = useRecoilValue(scenesState);

  const handleOrder = () => window.open(t('common.orderHref'));

  return (
    <HeaderScrollEffect>
      <AppBar
        position="sticky"
        color="inherit"
        sx={{ boxShadow: '0 1px 25px rgba(0, 0, 0, 0.15)' }}
      >
        <Toolbar component={Container}>
          <Grid container alignItems="center" wrap="nowrap" spacing={2}>
            <Grid item xs sx={{ display: 'flex' }}>
              <Hidden lgUp>
                <HeaderMenuDrawer items={items} />
              </Hidden>

              <Hidden lgDown>
                <Socials sx={{ marginRight: 'auto' }} />

                <HeaderNavigation
                  sx={{ marginLeft: 'auto' }}
                  items={items.filter(({ id }) => navigationLeft.includes(id))}
                />
              </Hidden>
            </Grid>

            <Grid item xs="auto">
              <Link to="/" className="logo">
                <Logo variant="circle" sx={{ marginBottom: -6 }} />
              </Link>
            </Grid>

            <Grid item xs sx={{ display: 'flex' }}>
              <Hidden lgDown>
                <HeaderNavigation
                  items={items.filter(({ id }) => navigationRight.includes(id))}
                />
              </Hidden>

              <HeaderOrderButton
                onClick={handleOrder}
                sx={{ marginLeft: 'auto' }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </HeaderScrollEffect>
  );
};

export default memo(Header);
