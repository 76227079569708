import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Avatar, Container, Typography, Link } from '@mui/material';
import { SentimentVeryDissatisfiedOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const ErrorScene = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box component={Container} mt={15}>
      <Typography
        variant="h1"
        align="center"
        color="primary"
        gutterBottom
        sx={{
          fontWeight: 700,
          fontSize: '8rem',
          [theme.breakpoints.up('sm')]: { fontSize: '14rem' },
          [theme.breakpoints.up('md')]: { fontSize: '20rem' },
        }}
      >
        404
      </Typography>

      <Typography variant="h5" align="center" color="secondary">
        {t('errors.404')}
        <SentimentVeryDissatisfiedOutlined />
      </Typography>

      <Box
        my={4}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Link to="/" component={RouterLink} sx={{ marginBottom: 2 }}>
          {t('common.goToHome')}
        </Link>

        <Avatar
          src="/cakes.svg"
          variant="square"
          sx={{
            width: 'auto',
            height: 'auto',
            maxWidth: 150,
          }}
        />
      </Box>
    </Box>
  );
};

export default ErrorScene;
