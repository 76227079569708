import React from 'react';

import { Router, Route, Switch, Redirect } from 'react-router-dom';

import history from './history';

import Layout from './layouts/MainLayout';
import PublicRoute from './components/PublicRoute';

import Home from './scenes/HomeScene';
import About from './scenes/AboutScene';
import Error from './scenes/ErrorScene';
import Gallery from './scenes/GalleryScene';
import Product from './scenes/ProductScene';
import Products from './scenes/ProductsScene';

const Routes = () => (
  <Router history={history}>
    <Switch>
      <PublicRoute exact path="/" component={Home} layout={Layout} />

      <PublicRoute exact path="/404" component={Error} layout={Layout} />

      <PublicRoute exact path="/about" component={About} layout={Layout} />

      <PublicRoute exact path="/gallery" component={Gallery} layout={Layout} />

      <PublicRoute exact path="/:productsId" component={Products} layout={Layout} />

      <PublicRoute exact path="/:productsId/:id" component={Product} layout={Layout} />

      <Route path="*">
        <Redirect to="/404" />
      </Route>
    </Switch>
  </Router>
);

export default Routes;
