import React from 'react';
import { Dialog, DialogContent, CardMedia } from '@mui/material';

import { isMobile } from '../../../helpers';

import ImageDialogTitle from './components/ImageDialogTitle';
import ImageDialogTransition from './components/ImageDialogTransition';

const ImageDialog = ({ open, onClose, name, image }) => {
  const isMobileBrowser = isMobile();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullScreen={isMobileBrowser}
      TransitionComponent={ImageDialogTransition}
    >
      <ImageDialogTitle name={name} onClose={onClose} />

      <DialogContent sx={{ display: 'flex' }}>
        <CardMedia
          image={image}
          component="img"
          loading="lazy"
          sx={{ borderRadius: 3, maxHeight: '100%' }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
