import React from 'react';
import { Slide, useScrollTrigger } from '@mui/material';

const HeaderScrollEffect = ({ window, children }) => {
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export default HeaderScrollEffect;
