import React from 'react';
import {
  Box,
  Card,
  CardMedia,
  CardActionArea,
  Typography,
  Divider,
} from '@mui/material';

const ProductCard = ({
  id,
  name,
  image,
  price,
  content,
  onClick,
  onImageClick,
  onPriceClick,
  ...rest
}) => {
  const getContent = () => ({ __html: content });

  return (
    <Card {...rest}>
      <CardActionArea
        component="span"
        onClick={() => onImageClick && onImageClick(id)}
        sx={{ overflow: 'hidden' }}
      >
        <CardMedia component="img" image={image} height={320} />
      </CardActionArea>

      {(name || content) && (
        <CardActionArea
          component="span"
          onClick={() => onClick && onClick(id)}
          sx={{ flexGrow: 1 }}
        >
          <Box p={2} sx={{ flexGrow: 1 }}>
            <Typography variant="h6" gutterBottom>
              {name}
            </Typography>

            {content && (
              <Typography
                variant="body2"
                component="div"
                dangerouslySetInnerHTML={getContent()}
              />
            )}
          </Box>

          {price && (
            <>
              <Divider variant="middle" />

              <Box py={1} px={2} sx={{ textAlign: 'center' }}>
                {price}
              </Box>
            </>
          )}
        </CardActionArea>
      )}
    </Card>
  );
};

export default ProductCard;
