import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Container, Typography } from '@mui/material';

import ProductCard from 'components/ProductsComponents/ProductCard';

const WeServe = ({ items = [] }) => {
  const { t } = useTranslation();

  return (
    <Container py={4} component={Box}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h4" className="font-roboto-slab" gutterBottom>
            {t('common.weServe')}
          </Typography>
        </Grid>

        {items.map(({ url, ...item }, i) => (
          <Grid item xs={12} sm={6} md key={i}>
            <ProductCard {...item} to={url} component={Link} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default WeServe;
