import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Card, CardMedia, Typography, Container, Button } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified'

const ProductProposalCard = ({ name, image, content, background }) => {
  const { t } = useTranslation();

  const getContent = () => ({ __html: content });

  return (
    <Card
      elevation={0}
      image={background}
      component={CardMedia}
      sx={{
        borderRadius: 0,
        backgroundAttachment: 'fixed',
      }}
    >
      <Container sx={{ paddingTop: 3, paddingBottom: 3 }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm>
            <Typography color="primary.dark" className="font-roboto-slab" sx={{ display:'flex', alignItems: 'center'}}>
              <VerifiedIcon fontSize="large" />
              &nbsp;
              {t('common.proposalTitle')}
            </Typography>

            <Typography variant="h4" className="font-roboto-slab" gutterBottom>
              {name}
            </Typography>

            <Typography
              variant="h6"
              component="div"
              gutterBottom
              dangerouslySetInnerHTML={getContent()}
            />

            <Button variant="contained" href={t('common.orderHref')}>{t('common.order')}</Button>
          </Grid>

          <Grid item xs={12} sm={5}>
            <CardMedia
              component="img"
              image={image}
              height="320"
              sx={{ borderRadius: 3, boxShadow: 10 }}
            />
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
};

export default ProductProposalCard;
