import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

const FooterCopyright = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <Box py={2} color="custom.main" bgcolor="primary.dark">
      <Typography align="center">
        © 2016 - {currentYear} 🤍 {t('common.brand')}
      </Typography>
    </Box>
  );
};

export default FooterCopyright;
