import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Grid, Container } from '@mui/material';

import { productsCardsState, sceneState } from 'api';
import { availableProducts } from 'enums';

import ProductTitle from 'components/ProductsComponents/ProductTitle';
import ProductCard from 'components/ProductsComponents/ProductCard';
import ImageDialog from 'components/CommonComponents/ImageDialog';

const ProductsScene = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { productsId } = useParams();
  const [product, setProduct] = useState();

  if (!availableProducts.includes(productsId)) history.push('/404');

  const cards = useRecoilValue(productsCardsState(productsId)) || [];
  const [{ background, ...page } = {}] =
    useRecoilValue(sceneState(productsId)) || [];

  const getContent = ({ content }) => ({ __html: content });

  const handleImage = (id) => setProduct(cards.find((card) => card.id === id));

  const handleCard = (id) => history.push('/'.concat(productsId, '/', id));

  return (
    <>
      <Helmet>
        <title>
          {t('common.brand').concat(' | ', t(`meta.${productsId}.title`))}
        </title>

        <meta
          name="description"
          content={t('common.brand').concat(
            ' | ',
            t(`meta.${productsId}.description`)
          )}
        />
      </Helmet>

      <ProductTitle name={t(`products.${productsId}`)} image={background} />

      <Box py={2} component={Container}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{ fontSize: 14, lineHeight: 1.2 }}
            dangerouslySetInnerHTML={getContent(page)}
          />

          {cards.map((card, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              <ProductCard
                {...card}
                elevation={1}
                sx={{ height: '100%' }}
                onClick={handleCard}
                onImageClick={handleImage}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <ImageDialog
        {...product}
        open={!!product}
        onClose={() => setProduct(undefined)}
      />
    </>
  );
};

export default ProductsScene;
