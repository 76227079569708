import React, { createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Link, IconButton, Box } from '@mui/material';
import { Facebook, Instagram } from '@mui/icons-material';

const Socials = ({ colored, ...rest }) => {
  const { t } = useTranslation();

  const socials = [
    {
      title: t('socials.facebook'),
      icon: createElement(Facebook),
      href: 'https://www.facebook.com/sweetgingerlviv',
      styles: {
        background: '#4267B2',
        '& svg': { fill: 'white' },
        '&:hover': { boxShadow: 2, background: '#4267B2' },
      },
    },
    {
      title: t('socials.instagram'),
      icon: createElement(Instagram),
      href: 'https://www.instagram.com/sweetgingerlviv',
      styles: {
        background: `radial-gradient(
          circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%
        )`,
        '& svg': { fill: 'white' },
        '&:hover': { boxShadow: 2 },
      },
    },
  ];

  return (
    <Box>
      <Grid container {...rest}>
        {socials.map(({ title, icon, href, styles }, i) => (
          <Grid item key={i}>
            <IconButton
              component={Link}
              {...{ href, title, color: 'primary', target: '_blank' }}
              sx={colored && styles}
            >
              {icon}
            </IconButton>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Socials;
