import React from 'react';
import { useRecoilValue } from 'recoil';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Container, Typography, CardMedia } from '@mui/material';

import { sceneState } from 'api';

import ProductTitle from 'components/ProductsComponents/ProductTitle';

const AboutScene = () => {
  const { t } = useTranslation();
  const [{ name, image, content, background } = {}] =
    useRecoilValue(sceneState('about')) || [];

  const getContent = () => ({ __html: content });

  return (
    <>
      <Helmet>
        <title>{t('common.brand').concat(' | ', t('meta.common.title'))}</title>

        <meta
          name="description"
          content={t('common.brand').concat(
            ' | ',
            t('meta.common.description')
          )}
        />
      </Helmet>

      <ProductTitle name={name} image={background} />

      <Container py={3} component={Box} sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" gutterBottom>
              {t('common.brand')}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <CardMedia
              component="img"
              image={image}
              height={320}
              sx={{
                borderRadius: 5,
                borderWidth: 5,
                borderColor: 'custom.main',
              }}
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <Box dangerouslySetInnerHTML={getContent()} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AboutScene;
