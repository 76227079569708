import React from 'react';
import Box from '@mui/material/Box';

import FormCheckbox from 'components/FormComponents/FormCheckbox';

const GalleryFilters = ({ items, onChange }) => {
  return (
    <>
      {items.map(({ label, checked }, i) => (
        <Box key={i}>
          <FormCheckbox
            name={label}
            label={label}
            checked={checked}
            onChange={onChange}
          />
        </Box>
      ))}
    </>
  );
};

export default GalleryFilters;
