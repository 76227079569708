import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Paper } from '@mui/material';

const Logo = ({ variant = 'square', sx }) => {
  const { t } = useTranslation();

  return (
    <Avatar
      component={Paper}
      variant={variant}
      elevation={0}
      src="/logo.svg"
      alt={t('common.brand')}
      sx={{
        width: 130,
        height: 130,
        ...(variant === 'circle' && {
          borderWidth: 3,
          borderStyle: 'solid',
          borderColor: 'primary.main',
          '& img': { transform: 'scale(1.0) translateY(-5px)' },
        }),
        ...sx,
      }}
    />
  );
};

export default Logo;
