import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CardMedia,
  Container,
  Breadcrumbs,
  Typography,
} from '@mui/material';

const ProductTitle = ({ name, image }) => {
  const { t } = useTranslation();
  const { productsId, id } = useParams();

  return (
    <>
      <Box
        py={5}
        image={image}
        component={CardMedia}
        sx={{ backgroundColor: 'custom.light' }}
      >
        <Container sx={{ textAlign: 'center' }}>
          <Typography
            variant="h3"
            className="font-roboto-slab"
            color={(image && 'custom.main') || 'text.secondary'}
            sx={{ textShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)' }}
          >
            {name}
          </Typography>
        </Container>
      </Box>

      {productsId && id && (
        <Container pt={2} component={Box}>
          <Breadcrumbs sx={{ margin: 'auto' }}>
            <Link to={`/${productsId}`}>{t(`products.${productsId}`)}</Link>

            <Typography>{name}</Typography>
          </Breadcrumbs>
        </Container>
      )}
    </>
  );
};

export default ProductTitle;
