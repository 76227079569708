import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SwipeableDrawer, Divider, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import Logo from '../../../Logo';
import Socials from '../../../Socials';
import HeaderNavigation from '../HeaderNavigation';

const HeaderMenuDrawer = ({ items }) => {
  const [open, setOpen] = useState(false);
  const params = useParams();

  useEffect(() => setOpen(false), [params]);

  return (
    <>
      <IconButton onClick={() => setOpen(true)} color="primary">
        <MenuIcon />
      </IconButton>

      <SwipeableDrawer
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <Logo sx={{ margin: '15px auto' }} />

        <HeaderNavigation
          orientation="vertical"
          items={items}
          sx={{ marginBottom: 'auto' }}
        />

        <Divider variant="middle" />

        <Socials
          colored
          spacing={2}
          justifyContent="center"
          sx={{ marginTop: 3, marginBottom: 4 }}
        />
      </SwipeableDrawer>
    </>
  );
};

export default HeaderMenuDrawer;
